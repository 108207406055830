import { Provider, teamsTheme, Loader } from '@fluentui/react-northstar';
import { HashRouter as Router, Redirect, Route } from 'react-router-dom';
import { useTeamsFx } from '@microsoft/teamsfx-react';
import Tab from './Tab';
import './App.css';
import { TeamsFxContext } from './Context';
import MyBookingsTab from './MyBookingsTab';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const teamsFx = useTeamsFx();
  const tenantId = teamsFx?.context?.tid;
  const { loading, theme, themeString, teamsfx } = teamsFx;

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsfx, tenantId }}>
      <Provider
        theme={theme || teamsTheme}
        styles={{ backgroundColor: '#eeeeee' }}
      >
        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path="/tab" component={Tab} />
              <Route exact path="/my-bookings" component={MyBookingsTab} />
            </>
          )}
        </Router>
      </Provider>
    </TeamsFxContext.Provider>
  );
}
