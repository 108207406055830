import { useContext } from 'react';
import { TeamsFxContext } from './Context';
import { MyBookings } from './sample/MyBookings';

export default function MyBookingsTab() {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div className={themeString === 'default' ? '' : 'dark'}>
      <MyBookings />
    </div>
  );
}
