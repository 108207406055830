import { useContext, useEffect, useState } from 'react';
import './Welcome.css';
import { TeamsFxContext } from '../Context';
import { Loader } from '@fluentui/react-northstar';
import { getHomeUrl } from '../helpers';

export interface Settings {
  Enabled: boolean;
  HomeUrl: string;
  DefaultPage: string;
  HomePageHtml: any;
}
export function MyBookings(props: { environment?: string }) {
  const { tenantId } = useContext(TeamsFxContext);
  const [settings, setSettings] = useState<Settings>();
  const [url, setUrl] = useState<string>();
  const [display, setDisplay] = useState<any>('hidden');

  useEffect(() => {
    if (tenantId) {
      fetch(
        `https://spaces.nexudus.com/api/integrations/msteams/settings/${tenantId}`
      )
        .then((response) => {
          response.json().then(setSettings);
        })
        .catch((err) => {
          alert('Error');
        });
    }
  }, [tenantId]);

  useEffect(() => {
    if (settings) {
      const firstUrl = `${getHomeUrl(
        settings
      )}/profile/bookings?public&?public&hide-main-menu=true`;
      setUrl(firstUrl);
      setTimeout(() => setDisplay('visible'), 1000);
    }
  }, [settings]);

  return (
    <div className="welcome page">
      {display == 'hidden' && (
        <>
          <Loader styles={{ paddingTop: 100 }} />
        </>
      )}
      <iframe
        title="My bookings"
        style={{
          visibility: display,
          border: 0,
          width: '100%',
          height: '100vh',
        }}
        src={url}
      ></iframe>
    </div>
  );
}
